import "./Main.css";
import Home from "./Home";
import Wave1 from "./Wave1";
import Wave2 from "./Wave2";
import Mempelai from "./Mempelai";
import Ayat from "./Ayat";
import Wave3 from "./Wave3";
import Acara from "./Acara";
import Gift from "./Gift";
import Galery from "./Galery";
import Ucapan from "./Ucapan";
import {useUndanganContext} from "../../context/UndanganContextProvider";

const Main = () => {
	// CONTEXT
	const data = useUndanganContext();
	
	return (<main className="text-light wd-main" data-bs-spy="scroll" data-bs-target="#navbar-menus" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true" tabIndex="0">
		<Home />
		<Wave1 color={"#F5F3ED"} />
		<Ayat />
		<Wave2 color={"#F5F3ED"} />
		<Mempelai />
		<Wave3 color={"#F5F3ED"} />
		<Acara />
		<Galery />
		<Ucapan />
		<Wave3 color={"#F5F3ED"} />
		{["", undefined, null].includes(data?.dari) &&
		<Gift />
		}
	</main>);
}

export default Main;