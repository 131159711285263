import lookingImg from "../assets/huh-looking.gif";
import yaseenImg from "../assets/yaseen.gif";
import catTeaseImg from "../assets/cat-tease.gif";
import risadaImg from "../assets/risada.gif";
import peachGommaImg from "../assets/peachandgoma.gif";
import peachCuteImg from "../assets/peach-cute.gif";
import cuddlesPandaImg from "../assets/cuddles-panda.gif";
import mochiCatImg from "../assets/mochi-mochi-peach-cute-cat.gif";
import cuteImg from "../assets/cute.gif";

export const MESSAGE_1 = [
	{
		step: 0,
		condition: 'eq',
		message:  `Masih marah kah?`,
		image: lookingImg,
	},
	{
		step: 1,
		condition: 'eq',
		message:  `Ayaaang, maapin aku ath.. Jangan marah lama-lamaa!`,
		image: yaseenImg,
	},
	{
		step: 2,
		condition: 'eq',
		message:  `Maapin aku gaak?`,
		image: catTeaseImg,
	},
	{
		step: 3,
		condition: 'eq',
		message:  `Harus baikann ihhhh.. Jangan marah ama akuuu`,
		image: risadaImg,
	},
	{
		step: 4,
		condition: 'eq',
		message:  `Tuhkan tombolnya lari lagiii`,
		image: peachGommaImg,
	},
	{
		step: 5,
		condition: 'eq',
		message:  `Gak cape ap? Harus gak marah ih ayangg`,
		image: peachCuteImg,
	},
	{
		step: 6,
		condition: 'eq',
		message:  `Ayo donggg, pwissss`,
		image: cuddlesPandaImg,
	},
	{
		step: 7,
		condition: 'eq',
		message:  `pwissss`,
		image: mochiCatImg,
	},
	{
		step: 8,
		condition: 'gte',
		message:  `yaudah deh kalo masih marah.. :(`,
		image: cuteImg,
	}
]