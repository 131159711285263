export const KONFIGURASI = {
	MEMPELAI: {
		PRIA: {
			PHOTO: 'https://projects.dika.web.id/assets/prewed/EDIT_(26).jpg',
			NAMA_LENGKAP: 'Ferdhika Yudira Diputra, S.Tr.Kom',
			NAMA_PANGGILAN: 'Ferdhika',
			AYAH: 'Jajang Sumpena',
			IBU: 'Ika Kartika',
			SOCMED: {
				IG: 'https://instagram.com/ferdhika31',
			}
		},
		WANITA: {
			PHOTO: 'https://bynoia.com/wp-content/uploads/2023/10/Celline-2.png',
			NAMA_LENGKAP: 'Nur Amalia Hasna, A.Md.Kep',
			NAMA_PANGGILAN: 'Hasna',
			AYAH: 'Mohamad Yusuf',
			IBU: 'Masfupah',
			SOCMED: {
				IG: 'https://instagram.com/hasnaamalia30',
			}
		},
	},
	SYUKURAN: {
		TANGGAL: '2024-06-26',
		JAM: {
			MULAI: '10:00:00',
			SELESAI: null,
		},
		TEMPAT: {
			NAMA: 'Kediaman Mempelai Pria',
			ALAMAT: 'Jl. Bakti Sejati No.19 RT.02 RW.09 Batureok, Kel. Gudang Kahuripan, Kec. Lembang, Kab. Bandung Barat, Jawa Barat 40391',
			LAT_LNG: '-6.8214349,107.6111233,16z',
			URL: 'https://maps.app.goo.gl/kA4swcsfuVWWfVY87',
		},
	},
	AKAD: {
		TANGGAL: '2024-07-06',
		JAM: {
			MULAI: '10:00:00',
			SELESAI: '11:00:00',
		},
		TEMPAT: {
			NAMA: 'Kantor Desa Tridaya Sakti',
			ALAMAT: 'Jl. Sentosa RT.05 RW.07, Kel. Tridayasakti, Kec. Tambun Selatan, Kabupaten Bekasi, Jawa Barat 17510',
			LAT_LNG: '-6.2471848,107.0696306,19z',
			URL: 'https://maps.app.goo.gl/GhtZPXYxh9Ba9WfF8',
		},
	},
	RESEPSI: {
		TANGGAL: '2024-07-06',
		JAM: {
			MULAI: '12:00:00',
			SELESAI: '14:00:00',
		},
		TEMPAT: {
			NAMA: 'Kantor Desa Tridaya Sakti',
			ALAMAT: 'Jl. Sentosa RT.05 RW.07, Kel. Tridayasakti, Kec. Tambun Selatan, Kabupaten Bekasi, Jawa Barat 17510',
			LAT_LNG: '-6.2471848,107.0696306,19z',
			URL: 'https://maps.app.goo.gl/GhtZPXYxh9Ba9WfF8',
		},
	},
}

