import React, { createContext, useContext } from "react";

const UndanganContext = createContext();

export const useUndanganContext = () => {
	return useContext(UndanganContext);
};

export const UndanganContextProvider = ({ children, ...props }) => {
	return (
		<UndanganContext.Provider {...props} >
			{children}
		</UndanganContext.Provider>
	);
};

export default UndanganContextProvider;
