import logoBRI from '../assets/images/bank/logo-bri.png'
import logoFlip from '../assets/images/bank/logo-flip.png'
import logoMANDIRI from '../assets/images/bank/logo-mandiri.png'
import logoJago from '../assets/images/bank/logo-jago.png'
import logoBluBca from '../assets/images/bank/logo-blu-bca.png'

export const BANK = [
	// {
	// 	image: logoJago,
	// 	bank: 'Bank Jago',
	// 	nama: 'Ferdhika Yudira Diputra',
	// 	nomor: '1013-0749-7020',
	// 	type: 'norek',
	// },
	{
		image: logoBRI,
		bank: 'Bank BRI',
		nama: 'Ferdhika Yudira Diputra',
		nomor: '1549-0101-0288-500',
		type: 'norek',
	},
	{
		image: logoMANDIRI,
		bank: 'Bank Mandiri',
		nama: 'Nur Amalia Hasna',
		nomor: '1310-0212-5877-9',
		type: 'norek',
	},
	{
		image: logoBluBca,
		bank: 'Bank Blu BCA',
		nama: 'Ferdhika Yudira Diputra',
		nomor: '0089-6188-8922',
		type: 'norek',
	},
	// {
	// 	image: logoFlip,
	// 	bank: 'FLIP',
	// 	nama: 'Ferdhika Yudira Diputra',
	// 	nomor: 'https://flip.id/me/ferdhikayudiradiputra',
	// 	type: 'url',
	// },
]