import React, { useEffect, useState, useRef } from "react";
import { MAAP_MESSAGE } from "./consts";
import "./index.css";

const phoneNumber = "6283821708285";

const Maap = () => {
	const btnNo = useRef();

	// LOCAL STATE
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState(null);
	const [image, setImage] = useState(null);
	const [step, setStep] = useState(0);

	// USE EFFECT
	useEffect(() => {
		if (messages.length < 1) generateMessages();
	}, []);

	// UTILS
	const sendToWA = (textMsg) => {
		window.location.href = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${textMsg}&type=phone_number&app_absent=0`;
	};

	const generateMessages = () => {
		const tempMessages = MAAP_MESSAGE[0];
		setMessages(tempMessages);
		setMessage(tempMessages[0].message);
		setImage(tempMessages[0].image);
	}

	const setImageAndMessage = (step) => {
		const tempData = messages.find((m) => m.step === step);

		if (tempData) {
			const msg = tempData?.message;
			const img = tempData?.image;
			setMessage(msg);
			setImage(img);
		}
	}

	// UI HANDLER
	const onOverNo = () => {
		// init
		let tempStep = step + 1;
		setStep(tempStep);

		setImageAndMessage(tempStep);

		// move button
		let randX = Math.random() * 70;
		let randY = Math.random() * 80;

		if (tempStep < messages.length) {
			btnNo.current.style.left = randX + "%";
			btnNo.current.style.top = randY + "%";
		}
	};

	const onClickYes = () => {
		const waMessage = "Iya sayang, aku maaping kok :)";
		sendToWA(waMessage);
	};

	const onClickMarah = () => {
		if (step > messages.length) {
			const waMessage = "Aku masih marahhhh -,-";
			sendToWA(waMessage);
		} else {
			onOverNo();
		}
	};

	// RENDER
	return (
		<div className="wrapper">
			<span id="msg">{message}</span>
			<img src={image} alt="Hai :)" id="sticker" />
			<button id="yes" onClick={onClickYes}>
				Udah gak marah
			</button>
			<button id="no" ref={btnNo} onMouseOver={onOverNo} onClick={onClickMarah}>
				MASIH MARAH!
			</button>
		</div>
	);
};

export default Maap;
