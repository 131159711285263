import React, { useState, useRef } from "react";

const Kangen = () => {

	// RENDER
	return (
		<div className="wrapper">
			Kangennnn ih
		</div>
	);
};

export default Kangen;
