import React from "react";

const NotFound = () => {
	// RENDER
	return (
		<>
			<h1>Alamat palsu gaes.</h1>
		</>
	);
};

export default NotFound;
