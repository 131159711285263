import { useState, useEffect } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { getImageSize } from 'react-image-size';
import GalleryServices from "../../services/gallery.services";
import UcapanServices from "../../services/ucapan.services";
import {addDoc} from "firebase/firestore";

const Galery = () => {
	const numPerPage = 100;
	
    const [index, setIndex] = useState(-1);
	const [galleries, setGalleries] = useState([]);
	const [firstDoc, setFirstDoc] = useState(undefined);
	const [lastDoc, setLastDoc] = useState(undefined);
	const [pages, setPages] = useState(null);
	const [page, setPage] = useState(1);
	const [direction, setDirection] = useState(undefined);
	const currentImage = galleries[index];
	const nextIndex = (index + 1) % galleries.length;
	const nextImage = galleries[nextIndex] || currentImage;
	const prevIndex = (index + galleries.length - 1) % galleries.length;
	const prevImage = galleries[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

	// Fetch number of pages
	useEffect(async () => {
		GalleryServices.getNumPages(numPerPage).then((pages) => setPages(pages));
	}, []);

	useEffect(async () => {
		const startAfterDoc = direction === 'next' ? lastDoc : undefined;
		const endBeforeDoc = direction === 'prev' ? firstDoc : undefined;

		GalleryServices.getAll('created_at', direction, startAfterDoc, endBeforeDoc, numPerPage).then((response) => {
			setGalleries(response.result);
			setFirstDoc(response.firstDoc);
			setLastDoc(response.lastDoc);
		}).catch((e) => {
			console.log(e);
			alert(e)
		});
	}, []);

	// Handle pagination button clicks
	const handlePreviousClick = () => {
		if (page === 1) return;
		setDirection('prev');
		setPage(prev => prev - 1);
	};

	const handleNextClick = () => {
		if (page === pages) return;
		setDirection('next');
		setPage(prev => prev + 1);
	};

	const newImage = async (e) => {
		e.preventDefault();
		try {
			const baseUrl = 'https://res.cloudinary.com/dika/image/upload/';
			const folder = 'v1718970742/Pribadi/Project/Undangan/Galleries/';
			const pictureName = 'LNR_6915_OR.jpg'
			const dimensions = await getImageSize(`${baseUrl}${folder}${pictureName}`);
			console.log(dimensions);

			const data = {
				caption: "",
				height: dimensions.height,
				width: dimensions.width,
				original: `${baseUrl}${folder}${pictureName}`,
				src: `${baseUrl}w_450/${folder}${pictureName}`,
			};
			GalleryServices.create(data)
				.then(() => {
					alert("Created new item successfully!");
				})
				.catch((e) => {
					console.log(e);
					alert(e)
				});
			
		} catch (error) {
			console.error(error);
		}
	}
    
    return (
        <div className="container text-standard" id="galery">
            <div className="text-center">
                <h1 className="font-esthetic mt-4 mb-3" style={{fontSize: "3rem"}}>Galeri</h1>
                    
                <Gallery images={galleries} enableImageSelection={false} onClick={handleClick} />
                {!!currentImage && (
                    /* @ts-ignore */
                    <Lightbox
                        mainSrc={currentImage.original}
                        imageTitle={currentImage.caption}
                        mainSrcThumbnail={currentImage.src}
                        nextSrc={nextImage.original}
                        nextSrcThumbnail={nextImage.src}
                        prevSrc={prevImage.original}
                        prevSrcThumbnail={prevImage.src}
                        onCloseRequest={handleClose}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                    />
                )}
				
				{/*<button onClick={newImage}>Add New</button>*/}
            </div>
        </div>
    );
}

export default Galery;