import {BANK} from "../../consts/bank";
import qrisImg from "../../assets/images/qris2.jpeg";
import qrisLogo from "../../assets/images/bank/logo-qris.png";
import './Gift.css';

const Gift = () => {
    
    const copyToClipboard = (e, item) => {
        console.log(e)
        if (item?.type === 'url') {
            window.open(item?.nomor, '_blank');
        } else {
            const nomor = item?.nomor.replace(/-/g, '');
            navigator.clipboard.writeText(nomor);

            alert(`No. Rekening ${nomor} a/n ${item?.nama} berhasil disalin!`)

        }
    }

	const copyToClipboardAlamat = (e, alamat) => {
		console.log(e)
		navigator.clipboard.writeText(alamat);

		alert(`Alamat ${alamat} berhasil disalin!`)
	}
    
    return (<>
        <div className="light-section text-default" id="gift">
            <div className="py-4 container">

                <div className="text-center">
                    <h1 className="font-esthetic mt-0 mb-3" style={{fontSize: "3rem"}}>Wedding Gift</h1>

                    <p className="mb-1" style={{fontSize: "0.9rem"}}>
                        Tanpa mengurangi rasa hormat, bagi keluarga dan sahabat yang ingin mengirimkan hadiah atau memberikan tanda kasih untuk kami,
                        dapat melalui :
                    </p>

                    <div className="overflow-x-hidden">
                        <div className="row justify-content-center">

                            {BANK.map((item, index) => {
                                return (
                                    <div className="col-4 card-body border rounded-4 shadow p-3 m-3" key={index}>
                                        <img src={item?.image} className="img-fluid w-50 rounded" alt={item?.bank} />
                                        {item?.type !== 'url' && <>
                                            <p className="card-text mt-3 mb-0" style={{fontSize: "0.9rem"}}>No. Rekening</p>
                                            <p>{item?.nomor}</p>
                                        </>
                                        }
                                        <p className="card-text" style={{fontSize: "0.9rem"}}>a.n {item?.nama}</p>
                                        <div className="btn btn-dark btn-sm rounded-3" onClick={(e) => copyToClipboard(e, item)}>Salin No. Rekening</div>
                                    </div>
                                );
                            })}

                            <div className="col-4 card-body border rounded-4 shadow p-3 m-3">
                                <img src={qrisLogo} className="img-fluid w-25 rounded" alt={"QRIS"} />
                                <br/>
                                <br/>
                                <img src={qrisImg} className={"img-fluid rounded img-qris"} />
                                <p className="card-text mt-3 mb-0" style={{fontSize: "0.9rem"}}>QRIS</p>
                            </div>

							<div className="col-4 card-body border rounded-4 shadow p-3 m-3">
								<p><b>Alamat Penerima</b></p>
								<p className="card-text mt-3 mb-0" style={{fontSize: "0.9rem"}}>
									Ferdhika Yudira Diputra<br/>
									0851-5700-3108
								</p>
								<p className="card-text mt-3 mb-0" style={{fontSize: "0.9rem"}}>
									Jl. Bakti Sejati No. 19<br/>
									RT 02 RW 09 Batureok Lembang, Kel. Gudangkahuripan<br/>
									Kec. Lembang, Kab. Bandung Barat 40391
								</p>
								<div className="btn btn-dark btn-sm rounded-3" onClick={(e) => copyToClipboardAlamat(e, "Jl. Bakti Sejati No. 19 " +
									"RT 02 RW 09 Batureok Lembang, Kel. Gudangkahuripan " +
									"Kec. Lembang, Kab. Bandung Barat 40391"
								)}>Salin Alamat</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Gift;