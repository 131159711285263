import React from "react";

const HBDIndex = () => {
	// LOCAL STATE

	// USE EFFECT HOOK

	// RENDER
	return <div>Happy Birthday!</div>;
};

export default HBDIndex;
