import { useState, useEffect } from "react";
import {useUndanganContext} from "../../context/UndanganContextProvider";
import UcapanServices from "../../services/ucapan.services";

const humanizeDuration = require("humanize-duration");

const Ucapan = () => {
    // CONTEXT
    const data = useUndanganContext();
	const numPerPage = 100;

	// LOCAL STATE
	const [showAlert, setShowAlert] = useState(true);
	const [alertSuccess, setAlertSuccess] = useState(false);
	const [name, setName] = useState("");
	const [presence, setPresence] = useState(0);
	const [comment, setComment] = useState("");
	const [submitted, setSubmitted] = useState(false);
	const [ucapans, setUcapans] = useState([]);
	const [firstDoc, setFirstDoc] = useState(undefined);
	const [lastDoc, setLastDoc] = useState(undefined);
	const [pages, setPages] = useState(null);
	const [page, setPage] = useState(1);
	const [direction, setDirection] = useState(undefined);
	const [ipAddress, setIPAddress] = useState('');

	// Fetch number of pages
	useEffect(async () => {
		UcapanServices.getNumPages(numPerPage).then((pages) => setPages(pages));
		await getInfo();
		if (data?.namaTamu) {
			setName(data?.namaTamu);
		}
	}, []);

	useEffect(async () => {
		const startAfterDoc = direction === 'next' ? lastDoc : undefined;
		const endBeforeDoc = direction === 'prev' ? firstDoc : undefined;
		
		UcapanServices.getAll('created_at', direction, startAfterDoc, endBeforeDoc, numPerPage).then((response) => {
			setUcapans(response.result);
			setFirstDoc(response.firstDoc);
			setLastDoc(response.lastDoc);
		}).catch((e) => {
			console.log(e);
			alert(e)
		});
	}, [submitted, page]);

	// Handle pagination button clicks
	const handlePreviousClick = () => {
		if (page === 1) return;
		setDirection('prev');
		setPage(prev => prev - 1);
	};

	const handleNextClick = () => {
		if (page === pages) return;
		setDirection('next');
		setPage(prev => prev + 1);
	};

	const newComment = async (e) => {
		e.preventDefault();
		let tempPresence = "hadir";
		if (presence == 1) {
			tempPresence = "hadir";
		} else if (presence == 2) {
			tempPresence = "berhalangan";
		} else if (presence == 3) {
			tempPresence = "hadir_syukuran";
		}
		const data = {
			name: name,
			presence: tempPresence,
			comment: comment,
			ipAddress: ipAddress,
		};
		UcapanServices.create(data)
			.then(() => {
				console.log("Created new item successfully!");
				setSubmitted(true);
				setFirstDoc(undefined);
				setLastDoc(undefined);
				setPages(null);
				setPage(1);
				setAlertSuccess(true);
			})
			.catch((e) => {
				console.log(e);
				alert(e)
			});
	}
	
	const getInfo = async () => {
		fetch('https://api.ipify.org/?format=json')
			.then(response => response.json())
			.then(data => {
				setIPAddress(data?.ip)
			})
			.catch(error => console.log(error))
	}

	return (<>
		<section className="mt-5 p-0 text-standard" id="ucapan">
			<div className="container">

				<div className="card-body border rounded-4 shadow p-3">
					<h1 className="font-esthetic text-center mb-3" style={{ fontSize: "3rem" }}>Ucapan & Doa</h1>

					{alertSuccess &&
					<div id="information" className="alert alert-success alert-dismissible fade show" role="alert">
						<p className="m-0">Terima kasih atas ucapan dan doanya!</p>
						<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setAlertSuccess(!alertSuccess)}></button>
					</div>
					}

					<div className="mb-3">
						<label htmlFor="form-name" className="form-label">Nama</label>
						<input type="text" disabled={data?.namaTamu} value={name} onChange={(e) => setName(e.target.value)} className="form-control shadow-sm" id="form-name" placeholder="Isikan Nama Anda" />
					</div>

					<div className="mb-3">
						<label htmlFor="form-presence" className="form-label" id="label-kehadiran">Kehadiran</label>
						<select className="form-select shadow-sm" id="form-presence" onChange={ (e) => setPresence(e.target.value) } >
							{!data?.hideSyukuran &&
							<option value="3" >Hadir Syukuran</option>
							}
							<option value="1" defaultValue>Hadir Akad dan/atau Resepsi</option>
							<option value="2">Berhalangan</option>
						</select>
					</div>

					<div className="mb-3">
						<label htmlFor="form-comment" className="form-label">Ucapan & Doa</label>
						{showAlert &&
							<div id="information" className="alert alert-info alert-dismissible fade show" role="alert">
								<p className="m-0">Tinggalkan pesan/doa untuk kami, terima kasih.</p>
								<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(!showAlert)}></button>
							</div>
						}
						<textarea className="form-control shadow-sm" id="form-comment" rows="4" placeholder="Tulis Ucapan & Doa" onChange={(e) => {
							setComment(e.target.value)
						}}></textarea>
					</div>
					<div className="d-grid gap-2">
						<a className={`btn btn-primary btn-sm rounded-3 shadow m-1${submitted || (name === "" || comment === "") ? " disabled" : ""}`} onClick={newComment}>
							<i className="fa-solid fa-paper-plane me-1"></i> Kirim
						</a>
					</div>
				</div>

				<div className="mt-4 mb-2 text-standard shadow rounded-4" id="comments" style={{ maxHeight: "600px", overflow: "scroll"}}>
					{ucapans.map((item, index) => {
						return (
							<div className="card-body p-3 mx-0 mt-0 mb-3 rounded-4" data-parent="true" key={index}>
								<div className="d-flex flex-wrap justify-content-between align-items-center">
									<p className={`text-truncate m-0 p-0${item?.presence === "hadir_syukuran" ? " font-italic" : ""}`} style={{ fontSize: "0.95rem" }}>
										<strong className="me-1">{ item?.name }</strong>
										{ ["hadir", "hadir_syukuran"].includes(item?.presence) ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-solid fa-circle-xmark text-danger"></i>}
									</p>
									<small className="m-0 p-0" style={{ fontSize: "0.75rem" }}>
										{ humanizeDuration(new Date().getTime() - item.created_at?.toDate().getTime(), { language: "id" }) }
									</small>
								</div>
								<hr className="my-1" />
								<p className="mt-0 mb-1 mx-0 p-0" style={{ whiteSpace: "pre-wrap !important" }} dangerouslySetInnerHTML={{ __html: item?.comment }}>
								</p>
								{/*<div className="d-flex flex-wrap justify-content-between align-items-center" id="button-651d51fc-648a-4802-887e-9498b52a1fdc">*/}
								{/*	<div className="d-flex flex-wrap justify-content-start align-items-center">*/}
								{/*		<button style={{ fontSize: "0.8rem" }} onClick="comment.reply(this)" data-uuid="651d51fc-648a-4802-887e-9498b52a1fdc" className="btn btn-sm btn-outline-light rounded-3 py-0 me-1">Reply</button>*/}
								{/*	</div>*/}
								{/*	<div className="ms-auto">*/}
								{/*		<button style={{ fontSize: "0.8rem" }} onClick="like.like(this)" data-uuid="651d51fc-648a-4802-887e-9498b52a1fdc" className="btn btn-sm btn-outline-light rounded-2 p-0">*/}
								{/*			<div className="d-flex justify-content-start align-items-center">*/}
								{/*				<p className="my-0 mx-1" data-count-like="0">0 like</p>*/}
								{/*				<i className="me-1 fa-regular fa-heart"></i>*/}
								{/*			</div>*/}
								{/*		</button>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</div>
						)
					})}
				</div>

				{/*<nav className="d-flex justify-content-center mb-0">*/}
				{/*	<ul className="pagination mb-0">*/}
				{/*		<li className={`page-item${page === 1 ? ' disabled': ''}`} id="previous">*/}
				{/*			<button */}
				{/*				className="page-link" */}
				{/*				disabled={page === 1} */}
				{/*				onClick={handlePreviousClick}*/}
				{/*			>*/}
				{/*				<i className="fa-solid fa-circle-left me-1"></i>Previous*/}
				{/*			</button>*/}
				{/*		</li>*/}
				{/*		<li className={`page-item${page === pages ? ' disabled': ''}`} id="next">*/}
				{/*			<button className="page-link" disabled={page === pages}*/}
				{/*					onClick={handleNextClick}*/}
				{/*			>*/}
				{/*				Next<i className="fa-solid fa-circle-right ms-1"></i>*/}
				{/*			</button>*/}
				{/*		</li>*/}
				{/*	</ul>*/}
				{/*</nav>*/}
			</div>
		</section>
	</>);
}

export default Ucapan;