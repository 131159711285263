import db from '../../../utils/FirebaseFirestore';
import {
	addDoc,
	collection,
	endBefore,
	getDocs,
	limit,
	limitToLast,
	orderBy,
	query,
	startAfter,
	getCountFromServer,
} from 'firebase/firestore';

class UcapanServices {
	async getAll(orderByProp, direction, startAfterDoc, endBeforeDoc, numPerPage ) {
		// collection path
		const collectionsName = 'ucapans';
		
		const dataCollection = collection(db, collectionsName);

		// Define the initial data query with ordering and limit
		let dataQuery = query(dataCollection, orderBy(orderByProp, "desc"), limit(numPerPage));

		// Update query based on direction and provided documents
		if (direction === 'next' && startAfterDoc) {
			// For next direction, start after the provided document
			dataQuery = query(dataQuery, startAfter(startAfterDoc));
		} else if (direction === 'prev' && endBeforeDoc) {
			// For previous direction, end before the provided document and limit to last
			dataQuery = query(
				dataCollection,
				orderBy(orderByProp),
				endBefore(endBeforeDoc),
				limitToLast(numPerPage)
			);
		}

		// Get snapshot of documents based on the final query
		const datasSnapshot = await getDocs(dataQuery);

		// Extract data from each document and map to an array of objects
		const datas = datasSnapshot.docs.map((doc) => doc.data());

		// Return an object retrieved datas, and first and last documents
		return {
			result: datas, // Cast datas to an array of Product objects
			lastDoc: datasSnapshot.docs[datasSnapshot.docs.length - 1],
			firstDoc: datasSnapshot.docs[0],
		};
	}
	
	async getNumPages(numPerPage = 10) {
		// collection path
		const collectionsName = 'ucapans';
		const dataCollection = collection(db, collectionsName)
		const count = await getCountFromServer(dataCollection)
		return Math.ceil(count.data().count / numPerPage);
	}

	create(data) {
		return addDoc(collection(db, "ucapans"), {
			...data,
			created_at: new Date(),
		});
	}
}

export default new UcapanServices();