import React, { useState, useEffect } from "react";
import Kangen from "./components/Kangen";
import Maap from "./components/Maap";

const pages = [
	{
		key: "kangen",
		component: <Kangen />,
		title: "Kangen",
	},
	{
		key: "maap",
		component: <Maap />,
		title: "Maap",
	},
];

const SuratIndex = () => {
	// LOCAL STATE
	const [params, setParams] = useState(null);
	const [katanya, setKatanya] = useState(null);

	// USE EFFECT HOOK (SET PARAMS)
	useEffect(() => {
		setParams(new URLSearchParams(window.location.search));
	}, []);

	useEffect(() => {
		if (params) {
			let tempKatanya = params.get("katanya");
			if (tempKatanya) setKatanya(tempKatanya);
		}
	}, [params]);

	const page = pages.find((page) => page?.key === katanya);

	// RENDER
	return <>{page ? <>{page.component}</> : <>Katanya: {katanya}</>}</>;
};

export default SuratIndex;
