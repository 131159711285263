import AnniversaryIndex from './pages/anniversary';
import HBDIndex from './pages/hbd/index';
import SuratIndex from "./pages/surat";
import UndanganIndex from "./pages/undangan";

export const ROUTES = [
	{
		route: "anniversary",
		title: "Happy Anniversary!",
		component: <AnniversaryIndex />,
	},
	{
		route: "hbd",
		title: "Happy Birthday!",
		component: <HBDIndex />,
	},
	{
		route: "buat",
		title: "Surat untuk hasna",
		component: <SuratIndex />,
	},
	{
		route: "dari",
		title: "Undangan",
		component: <UndanganIndex hideSyukuran={true} />,
	},
	{
		route: "undangan",
		title: "Undangan",
		component: <UndanganIndex hideSyukuran={false} />,
	},
	{
		route: "diundang",
		title: "Undangan",
		component: <UndanganIndex hideSyukuran={true} />,
	},
];
