import { useEffect, useState } from "react";
import { HelmetProvider } from 'react-helmet-async';
import { ROUTES } from "./Routes";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import "./App.css";
import AppContextProvider from "./AppContextProvider";

const pages = ROUTES;

function App() {
	const [subdomain, setSubDomain] = useState(null);
	const [subsubdomain, setSubSubDomain] = useState(null);

	useEffect(() => {
		const host = window.location.host; // gets the full domain of the app
		let sliceSize = -2;
		if (host.includes("localhost")) {
			sliceSize = -1;
		} else if (host.includes("hasna")) {
			sliceSize = -4;
		}
		const arr = host
			.split(".").slice(0, sliceSize);
		console.log("arr", arr);
		if (arr.length > 0) {
			setSubDomain(arr[0]);
		}
		if (arr.length > 1) {
			setSubDomain(arr[1]);
			setSubSubDomain(arr[0]);
		}
	}, []);

	const page = pages.find((page) => page?.route === subdomain);

	return (
		<>
			<HelmetProvider>
				<AppContextProvider value={{
					subdomain,
					subsubdomain,
				}}>
				{page ? (
					page ? (
						page.component
					) : (
						<NotFound />
					)
				) : (
					<Home />
				)}
				</AppContextProvider>
			</HelmetProvider>
		</>
	);
}

export default App;
