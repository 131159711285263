import React from "react";
import "./SunFlower.css";

const SunFlower = () => {
	// LOCAL STATE

	// USE EFFECT HOOK

	// RENDER
	return (
		<>
			<div id="position" className="sunflower">
				<div className="head">
					<div id="eye-1" className="eye"></div>
					<div id="eye-2" className="eye"></div>
					<div className="mouth"></div>
				</div>
				<div className="petals"></div>
				<div className="trunk">
					<div className="left-branch"></div>
					<div className="right-branch"></div>
				</div>
				<div className="vase"></div>
			</div>
		</>
	);
};

export default SunFlower;
