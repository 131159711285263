import {useEffect, useState} from "react";
import {useUndanganContext} from "../../context/UndanganContextProvider";
import DateFormatter from "../../../../utils/DateFormatter";

const Acara = () => {
    // CONTEXT
    const data = useUndanganContext();
    
    // LOCAL STATE
    const [tanggalSyukuran, setTanggalSyukuran] = useState('');
    const [jamSyukuran, setJamSyukuran] = useState('');
    const [alamatSyukuran, setAlamatSyukuran] = useState('');
    const [urlSyukuran, setUrlSyukuran] = useState('');
	const [showSyukuran, setShowSyukuran] = useState(!data?.hideSyukuran);
    
    const [tanggalAkad, setTanggalAkad] = useState('');
    const [jamAkad, setJamAkad] = useState('');
    const [alamatAkad, setAlamatAkad] = useState('');
    const [urlAkad, setUrlAkad] = useState('');
    
    const [tanggalResepsi, setTanggalResepsi] = useState('');
    const [jamResepsi, setJamResepsi] = useState('');
    const [alamatResepsi, setAlamatResepsi] = useState('');
    const [urlResepsi, setUrlResepsi] = useState('');
    
    const [hari, setHari] = useState(0);
    const [jam, setJam] = useState(0);
    const [menit, setMenit] = useState(0);
    const [detik, setDetik] = useState(0);

    useEffect(() => {
        let countDownDate = (new Date(`${data?.akad?.tanggal} ${data?.akad?.jam?.mulai}`)).getTime();
        
        if (!data?.hideSyukuran) {
            countDownDate = (new Date(`${data?.syukuran?.tanggal} ${data?.syukuran?.jam?.mulai}`)).getTime();
			
			if (countDownDate < (new Date()).getTime()) {
				setShowSyukuran(false);
				countDownDate = (new Date(`${data?.akad?.tanggal} ${data?.akad?.jam?.mulai}`)).getTime();
			}
        }

        setInterval(() => {
            let distance = (countDownDate - (new Date()).getTime());
			if (distance > 0) {
				setHari(Math.floor(distance / (1000 * 60 * 60 * 24)));
				setJam(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
				setMenit(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
				setDetik(Math.floor((distance % (1000 * 60)) / 1000));
			} else {
				setHari(0);
				setJam(0);
				setMenit(0);
				setDetik(0);
			}
        }, 1000);
    }, [hari, menit, jam, detik]);
    
    useEffect(() => {
        const tglSyukuran = data?.syukuran?.tanggal;
        setTanggalSyukuran(DateFormatter.getDateFormat(tglSyukuran, 'dddd, DD MMMM YYYY'));
        const waktuSyukuran = `Pukul ${data?.syukuran?.jam?.mulai?.substring(0, 5)} WIB - ${data?.syukuran?.jam?.selesai ?? 'Selesai'}`;
        setJamSyukuran(waktuSyukuran);
        const aSyukuran = `${data?.syukuran?.tempat?.nama}<br/>${data?.syukuran?.tempat?.alamat}`;
        setAlamatSyukuran(aSyukuran);
        const linkSyukuran = `${data?.syukuran?.tempat?.url}`;
        setUrlSyukuran(linkSyukuran);
        
        const tglAkad = data?.akad?.tanggal;
        setTanggalAkad(DateFormatter.getDateFormat(tglAkad, 'dddd, DD MMMM YYYY'));
        const waktuAkad = `Pukul ${data?.akad?.jam?.mulai?.substring(0, 5)} ${data?.akad?.jam?.selesai ? '' : 'WIB'} - ${data?.akad?.jam?.selesai ? `${data?.akad?.jam?.selesai?.substring(0, 5)} WIB` : 'Selesai'}`;
        setJamAkad(waktuAkad);
        const aAkad = `${data?.akad?.tempat?.nama}<br/>${data?.akad?.tempat?.alamat}`;
        setAlamatAkad(aAkad);
        const linkAkad = `${data?.akad?.tempat?.url}`;
        setUrlAkad(linkAkad);
        
        const tglResepsi = data?.resepsi?.tanggal;
        setTanggalResepsi(DateFormatter.getDateFormat(tglResepsi, 'dddd, DD MMMM YYYY'));
        const waktuResepsi = `Pukul ${data?.resepsi?.jam?.mulai?.substring(0, 5)} ${data?.resepsi?.jam?.selesai ? '' : 'WIB'} - ${data?.resepsi?.jam?.selesai ? `${data?.resepsi?.jam?.selesai?.substring(0, 5)} WIB` : 'Selesai'}`;
        setJamResepsi(waktuResepsi);
        const aResepsi = `${data?.resepsi?.tempat?.nama}<br/>${data?.resepsi?.tempat?.alamat}`;
        setAlamatResepsi(aResepsi);
        const linkResepsi = `${data?.resepsi?.tempat?.url}`;
        setUrlResepsi(linkResepsi);
    }, []);
    
    return (<>
        <section className="light-section" id="tanggal">

            <div className="container">
                <div className="text-center">

                    <h1 className="font-esthetic py-3 pt-5" style={{fontSize: "2rem"}}>Waktu Menuju Acara {showSyukuran ? "Syukuran Pernikahan" : "Akad"}</h1>
                    <div className="border rounded-pill shadow py-2 px-4 mx-2 mb-4">

                        <div className="row justify-content-center" id="tampilan-waktu">
                            <div className="col-3 p-1">
                                <h3 className="d-inline m-0 p-0" id="hari">{hari}</h3><small className="ms-1 me-0 my-0 p-0 d-inline">Hari</small>
                            </div>
                            <div className="col-3 p-1">
                                <h3 className="d-inline m-0 p-0" id="jam">{jam}</h3><small className="ms-1 me-0 my-0 p-0 d-inline">Jam</small>
                            </div>
                            <div className="col-3 p-1">
                                <h3 className="d-inline m-0 p-0" id="menit">{menit}</h3><small className="ms-1 me-0 my-0 p-0 d-inline">Menit</small>
                            </div>
                            <div className="col-3 p-1">
                                <h3 className="d-inline m-0 p-0" id="detik">{detik}</h3><small className="ms-1 me-0 my-0 p-0 d-inline">Detik</small>
                            </div>
                        </div>
                    </div>

                    <p style={{fontSize: "0.9rem"}} className="mt-4 py-2">
                        Dengan memohon rahmat dan ridho Allah Subhanahu Wa Ta'ala, insyaAllah kami akan menyelenggarakan
                        acara :
                    </p>

                    <div className="overflow-x-hidden">
                        {(data?.hideSyukuran === false && showSyukuran) && 
                        <>
                            <div className="py-2 mb-4">
                                <h1 className="font-esthetic" style={{fontSize: "2rem"}}>Syukuran Pernikahan</h1>
                                <p>{tanggalSyukuran}</p>
                                <p>{jamSyukuran}</p>
                                <p dangerouslySetInnerHTML={{ __html: alamatSyukuran }}></p>
                                <a href={urlSyukuran} target="_blank" className="btn btn-outline-dark btn-sm rounded-pill shadow-sm mb-2 px-3">
                                    <i className="fa-solid fa-map-location-dot me-2"></i>Lihat Google Maps
                                </a>
                            </div>
                            <hr/>
                        </>
                        }
                        
                        <div className="py-2">
                            <h1 className="font-esthetic" style={{fontSize: "2rem"}}>Akad</h1>
                            <p>{tanggalAkad}</p>
                            <p>{jamAkad}</p>
                        </div>
                        <div className="py-2">
                            <h1 className="font-esthetic" style={{fontSize: "2rem"}}>Resepsi</h1>
                            <p>{tanggalResepsi}</p>
                            <p>{jamResepsi}</p>
                            <p dangerouslySetInnerHTML={{ __html: alamatResepsi }}></p>
                        </div>
                    </div>

                    <div className="py-2">
                        <a href={urlResepsi} target="_blank" className="btn btn-outline-dark btn-sm rounded-pill shadow-sm mb-2 px-3">
                            <i className="fa-solid fa-map-location-dot me-2"></i>Lihat Google Maps
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#F5F3ED" fillOpacity="1" d="M0,96L30,106.7C60,117,120,139,180,154.7C240,171,300,181,360,186.7C420,192,480,192,540,181.3C600,171,660,149,720,154.7C780,160,840,192,900,208C960,224,1020,224,1080,208C1140,192,1200,160,1260,138.7C1320,117,1380,107,1410,101.3L1440,96L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
        </svg>
    </>);
}

export default Acara;