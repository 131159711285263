import React, { useState, useRef, useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import moment from "moment";
import SunFlower from "./components/SunFlower";
import lovePng from "./components/Love.svg";
import "./index.css";

const firstDate = "2023-02-27";
const heartSplit = 7; // perminggu

const AnniversaryIndex = () => {
	// USEREF
	const animation = useRef(null);

	// LOCAL STATE
	const [dateBetweenDays, setDateBetweenDays] = useState(0);
	const [dateBetweenYears, setDateBetweenYears] = useState(0);
	const [hearts, setHearts] = useState([]);

	// USE EFFECT HOOK
	useEffect(() => {
		// This is default configuration for all the ticks
		animation.current = anime.timeline({
			direction: "alternate",
			elasticity: 300,
			loop: true,
		});
	}, []);

	useEffect(() => {
		animation.current.add({
			targets: `#heart`,
			scale: function (el, index) {
				return anime.random(0.3, 0.5);
			},
			rotate: {
				value: function (el, index) {
					return anime.random(-40, 40);
				},
				duration: function (el, index) {
					return anime.random(400, 2000);
				},
				easing: "easeInOutExpo",
			},
			delay: 50,
			translateX: function (el, index) {
				return anime.random(10, 50);
			},
			translateY: function (el, index) {
				return anime.random(10, 100);
			},
		});
	}, [hearts]);

	useEffect(() => {
		document
			.getElementById("backgroundMusic")
			.play()
			.catch((error) => {
				document.addEventListener(
					"click",
					() => {
						document.getElementById("backgroundMusic").play();
					},
					{ once: true }
				);
			});

		const startDate = moment(firstDate);
		const endDate = moment();
		const inDays = endDate.diff(startDate, "days");
		const inYears = endDate.diff(startDate, "years");

		setDateBetweenDays(inDays);
		setDateBetweenYears(inYears);

		// set hearts
		let tempHearts = [];
		for (let i = 0; i < Math.floor(inDays / heartSplit); i++) {
			tempHearts.push(i);
		}
		setHearts(tempHearts);
	}, []);

	// RENDER
	return (
		<>
			<h1>Happy Anniversary Dear</h1>
			<p />
			<h3>
				Jika aku harus kembali memilih, aku akan tetap memilihmu.
				<br />
				Selamat hari jadi yang ke{" "}
				<b>{(dateBetweenDays / 365).toPrecision(2)} tahun</b> (
				{dateBetweenDays} hari) sayang{" "}
				<img src={lovePng} alt="love" title="Love" />.<br />
				Kamu percaya ga kalo gambar lope nya bakal tumbuh tiap minggu?
				:))
			</h3>

			{hearts.map(() => {
				return (
					<div
						id="heart"
						style={{
							left: Math.random() * 460,
							top: Math.random() * 440,
						}}
					></div>
				);
			})}

			<audio autoPlay={true} id="backgroundMusic">
				<source
					src="https://projects.dika.web.id/musik/Raim Laode - Komang.mp3"
					type="audio/mpeg"
				/>
				Your browser does not support the audio element.
			</audio>

			<SunFlower />
		</>
	);
};

export default AnniversaryIndex;
