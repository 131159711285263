import {useEffect, useState} from "react";
import {useUndanganContext} from "../../context/UndanganContextProvider";
import DateFormatter from "../../../../utils/DateFormatter";
import bungaAtas from "../../assets/images/web/bunga-atas.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import mainImg from "../../assets/images/galeri/main.jpeg";

const Home = () => {
	// CONTEXT
	const data = useUndanganContext();
	
	// LOCAL STATE
	const [day, setDay] = useState('');
	const [date, setDate] = useState('');
	const [namaPria, setNamaPria] = useState(data?.mempelai?.pria?.nama_panggilan);
	const [namaWanita, setNamaWanita] = useState(data?.mempelai?.wanita?.nama_panggilan);
	const [urlSaveDate, setUrlSaveDate] = useState(null);
	
	useEffect(() => {
		const tanggalResepsi = data?.resepsi?.tanggal;
		let hari = DateFormatter.getDayName(tanggalResepsi);
		let tanggal = DateFormatter.getDateFormat(tanggalResepsi, 'DD MMMM YYYY');
		setDay(hari);
		setDate(tanggal);
		
		// set save the date
		const title = encodeURIComponent(`The Wedding of ${namaPria} and ${namaWanita}`);
		const text = encodeURIComponent(`The Wedding of ${namaPria} and ${namaWanita} | ${tanggal} | ${data?.resepsi?.tempat?.alamat} | ${data?.resepsi?.jam?.mulai} - ${data?.resepsi?.jam?.selesai}`);
		// const dates = encodeURIComponent(`${tanggalResepsi}T${data?.resepsi?.jam?.mulai.replace('.', '')}00/${tanggalResepsi}T${data?.resepsi?.jam?.selesai.replace('.', '')}00`);
		const location = encodeURIComponent(data?.resepsi?.tempat?.url);
		
		setUrlSaveDate(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${text}&dates=20230315T100000/20230315T110000&location=${location}`);
	}, [data?.resepsi?.tempat?.url]);
	
	return (<>
		<section className="wd-container text-default" id="home">
			
			<span className="bunga-atas">
				<img src={bungaAtas} sizes={"100w"} />
			</span>

			<div className="container text-center pt-4">
				<p className="my-4" style={{fontSize: "13px", color: "rgb(103, 73, 63)"}}>THE WEDDING OF</p>
				<h1 className="my-4" style={{fontSize: "38px", color:"rgb(151, 98, 122)"}}>{namaWanita} & {namaPria}</h1>
				<div className="py-4">
					<div className="img-crop shadow mx-auto photo-main">
						<LazyLoadImage 
							src={'https://projects.dika.web.id/assets/prewed/main_small.jpg'}
							PlaceholderSrc={'https://projects.dika.web.id/assets/prewed/main_small.jpg'}
							alt="bg"
						/>
					</div>
				</div>
				{data?.hideSyukuran && <>
					<p className="mb-0" style={{fontSize: "20px", color: "rgb(103, 73, 63)"}}>{day}, {date}</p>

					<a className="btn btn-outline-dark btn-sm shadow rounded-pill px-3 my-2" target="_blank" href={urlSaveDate}>
						<i className="fa-solid fa-calendar-check me-2" style={{color: "rgb(103, 73, 63)"}}></i>Save The Date
					</a>
				</>}

				<div className="d-flex justify-content-center align-items-center mt-4 mb-2">
					<div className="mouse-animation">
						<div className="scroll-animation"></div>
					</div>
				</div>

				<p className="m-0" style={{color: "rgb(103, 73, 63)"}}>Scroll Down</p>
			</div>
		</section>
	</>)
}

export default Home;