const Ayat = () => {
    return (
        <div className="light-section text-standard">
            <div className="text-center">

                <h1 className="font-ubuntu mt-0 mb-3" style={{fontSize: "2rem"}}>
                    Allah Subhanahu Wa Ta'ala berfirman
                </h1>

                <p style={{fontSize: "0.9rem"}} className="px-2">
                    Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari
                    jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu
                    rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda
                    (kebesaran Allah) bagi kaum yang berpikir.
                </p>

                <p className="mb-1 text-black"><strong>QS. Ar-Rum Ayat 21</strong></p>
            </div>
        </div>
  );
}

export default Ayat;