const Footer = () => {
    return (<>
        <footer className={"light-section text-default"}>
            <div className="container">
                <div className="text-center">

                    <p style={{fontSize: "0.9rem"}} className="pt-2 pb-1 px-2">
                        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila, Bapak / Ibu / Saudara / i.
                        berkenan hadir untuk memberikan do'a restunya kami ucapkan terimakasih.
                    </p>

                    <h1 className="font-ubuntu">Wassalamualaikum Warahmatullahi Wabarakatuh</h1>
                    <h1 className="font-arabic py-4 px-2" style={{fontSize: "2rem"}}>اَلْحَمْدُ لِلّٰهِ رَبِّ الْعٰلَمِيْنَۙ</h1>

                    <hr className="mt-3 mb-2" />

                        <div className="row align-items-center justify-content-between flex-column flex-sm-row">
                            <div className="col-auto">
                                <small className="text-default">
                                    Build with<i className="fa-solid fa-heart mx-1"></i>dika.web.id
                                </small>
                            </div>
                            <div className="col-auto">
                                <small>
                                    <i className="fa-brands fa-github me-1"></i><a target="_blank" href="https://github.com/ferdhika31" className={"text-decoration-none"}>ferdhika31</a>
                                </small>
                            </div>
                        </div>
                </div>
            </div>
        </footer>
    </>)
}

export default Footer;