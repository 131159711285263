import "./Mempelai.css";
import {useUndanganContext} from "../../context/UndanganContextProvider";
import {useState} from "react";
import photoPria from "../../assets/images/galeri/pria4.jpg";
import photoWanita from "../../assets/images/galeri/wanita4.jpg";

const Mempelai = () => {
	// CONTEXT
	const data = useUndanganContext();
	
	// LOCAL STATE
	const [pria, setPria] = useState(data?.mempelai?.pria);
	const [wanita, setWanita] = useState(data?.mempelai?.wanita);
	
	return (<>
		<section className="container" id="mempelai">

			<div className="text-center text-standard">
				<h1 className="font-arabic pt-5 py-4 px-2" style={{fontSize: "2rem"}}>بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ</h1>
				<h1 className="font-ubuntu py-4 px-2" style={{fontSize: "2rem"}}>Assalamualaikum Warahmatullahi Wabarakatuh</h1>

				<p className="pb-3 px-3">
					Tanpa mengurangi rasa hormat. Kami mengundang Bapak/Ibu/Saudara/i serta kerabat
					sekalian untuk menghadiri acara pernikahan kami:
				</p>
				
				<div className="row weddm-wrapper justify-content-md-center">
					<div className={"col-md-3 text-center"}>
						<div className="">
							<div className="img-crop shadow mx-auto mb-3">
								<img fetchpriority="high" decoding="async" width="229" height="369" src={photoWanita} className="mb-2 weddm-image" alt="" data-lazy-src={wanita?.photo} data-ll-status="loaded" />
							</div>

							<div className="weddm-image-box-content">
								<h3 className="weddm-image-box-title">{wanita?.nama_lengkap}</h3>
								<p className="mt-1 mb-0" style={{fontSize: "1.25rem"}}>Putri</p>
								<p className="mb-0">Bapak {wanita?.ayah}</p>
								&
								<p className={"mb-1"}>Ibu {wanita?.ibu}</p>
							</div>
						</div>
					</div>
					<div className={"col-md-1 my-auto"}>
						<h1 className="font-esthetic my-4" style={{fontSize: "4rem"}}>{'&'}</h1>
					</div>
					<div className={"col-md-3 text-center"}>
						<div className="">
							<div className="img-crop shadow mx-auto mb-3">
								<img fetchpriority="high" decoding="async" width="229" height="369" src={photoPria} className="mb-2 weddm-image" alt="" data-lazy-src={pria?.photo} data-ll-status="loaded" />
							</div>
							
							<div className="weddm-image-box-content">
								<h3 className="weddm-image-box-title">{pria?.nama_lengkap}</h3>
								<p className="mt-1 mb-0" style={{fontSize: "1.25rem"}}>Putra</p>
								<p className="mb-0">Bapak {pria?.ayah}</p>
								&
								<p className={"mb-0"}>Ibu {pria?.ibu}</p>
							</div>
						</div>
					</div>
				</div>

				{/*<div className="overflow-x-hidden">*/}
				{/*	<div>*/}
				{/*		<div className="img-crop border border-3 border-light shadow my-4 mx-auto">*/}
				{/*			<img src="" alt="cowo" />*/}
				{/*		</div>*/}
				{/*		<h1 className="font-esthetic" style={{fontSize: "3rem"}}>Ferdhika</h1>*/}
				{/*		<p className="mt-3 mb-0" style={{fontSize: "1.25rem"}}>Putra</p>*/}
				{/*		<p className="mb-0">Bapak ... & Ibu ...</p>*/}
				{/*	</div>*/}
				
				{/*	<h1 className="font-esthetic my-4" style={{fontSize: "4rem"}}>{'&'}</h1>*/}
				
				{/*	<div>*/}
				{/*		<div className="img-crop border border-3 border-light shadow my-4 mx-auto">*/}
				{/*			<img src="" alt="cewe" />*/}
				{/*		</div>*/}
				{/*		<h1 className="font-esthetic" style={{fontSize: "3rem"}}>Hasna</h1>*/}
				{/*		<p className="mt-3 mb-0" style={{fontSize: "1.25rem"}}>Putri</p>*/}
				{/*		<p className="mb-0">Bapak ... & Ibu ...</p>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</section>
	</>);
}

export default Mempelai;