import {useUndanganContext} from "../context/UndanganContextProvider";

const Menu = () => {
	// CONTEXT
	const data = useUndanganContext();
	
	return (<>
		<div style={{overflowY: "hidden"}}>
			<nav className="navbar navbar-light bg-light navbar-expand fixed-bottom rounded-top-4 p-0" id="navbar-menus">
				<ul className="navbar-nav nav-justified w-100 align-items-center">
					<li className="nav-item">
						<a className="nav-link" href="#home">
							<i className="fas fa-home"></i>
							<span className="d-block" style={{ fontSize: "0.7rem" }}>Beranda</span>
						</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="#mempelai">
							<i className="fa-solid fa-user-group"></i>
							<span className="d-block" style={{ fontSize: "0.7rem" }}>Mempelai</span>
						</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="#tanggal">
							<i className="fa-solid fa-calendar-check"></i>
							<span className="d-block" style={{ fontSize: "0.7rem" }}>Tanggal</span>
						</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="#galery">
							<i className="fa-solid fa-images"></i>
							<span className="d-block" style={{ fontSize: "0.7rem" }}>Galeri</span>
						</a>
					</li>
					{["", undefined, null].includes(data?.dari) &&
					<li className="nav-item">
						<a className="nav-link" href="#gift">
							<i className="fa-solid fa-gift"></i>
							<span className="d-block" style={{ fontSize: "0.7rem" }}>Hadiah</span>
						</a>
					</li>
					}
					{/*<li className="nav-item">*/}
					{/*	<a className="nav-link" href="#ucapan">*/}
					{/*		<i className="fa-solid fa-comments"></i>*/}
					{/*		<span className="d-block" style={{ fontSize: "0.7rem" }}>Ucapan</span>*/}
					{/*	</a>*/}
					{/*</li>*/}
				</ul>
			</nav>
		</div>
	</>);
}

export default Menu;