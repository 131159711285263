import React, {useEffect} from "react";
import {useUndanganContext} from "../context/UndanganContextProvider";
import lagu from "../assets/musics/nyoman_paul_andi_rianto-the_way_you_look_at_me.mp3";
import "./Music.css";

const Music = ({ autoplay = true }) => {

    // CONTEXT
    const data = useUndanganContext();

    useEffect(() => {
        playAudio();
    }, [autoplay]);

    const playAudio = () => {
        const audioEl = document.getElementsByClassName("audio-element")[0]
        if (!data.isPlaying) {
            data.setIsPlaying(true);
            audioEl.play()
        } else {
            data.setIsPlaying(false);
            audioEl.pause()
        }
    }
    
    return (
    <>
        <audio className="audio-element" loop={true}>
            <source src={lagu}></source>
        </audio>
        <i className={`fa fa-music${data.isPlaying ? '' : ' pause'}`} onClick={playAudio}></i>
    </>
    )
}

export default Music;