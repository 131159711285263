import * as moment from 'moment';
import 'moment/locale/id';

class DateFormatter {
	  static getDayName(date) {
		  const myDays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum\'at', 'Sabtu'];
		  const thisDate = moment(date).toDate();
		  let thisDay = thisDate.getDay();
		  return myDays[thisDay];
	  }
	  
	  static getMonthName(date) {
		  var months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
		  const thisDate = moment(date).toDate();
		  let thisMonth = thisDate.getMonth();
		  return months[thisMonth];
	  }
	  
	  static getDateFormat(date = new Date(), format="DD-MM-YYYY") {
		  const thisDate = moment(date).toDate();
		  return moment(thisDate).format(format);
	  }
}

export default DateFormatter;