import React, { createContext, useContext } from "react";

const AppContext = createContext();

export const useAppContext = () => {
	return useContext(AppContext);
};

export const AppContextProvider = ({ children, ...props }) => {
	return (
		<AppContext.Provider {...props} >
			{children}
		</AppContext.Provider>
	);
};

export default AppContextProvider;
