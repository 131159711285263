import React from "react";
// import { ROUTES } from "../Routes";
import "./Home.css";

const Home = () => {
	// LOCAL STATE

	// USE EFFECT HOOK

	// RENDER
	return (
		<div className="home">
			<h2 data-text="Hello World!">Hello World!</h2>
			{/* {ROUTES.map((page) => (
				<div key={page.route}>
					<a
						href={
							window.location.protocol +
							"//" +
							page.route +
							"." +
							window.location.host
						}
					>
						{page.title}
					</a>
				</div>
			))} */}
		</div>
	);
};

export default Home;
