import React, { useState, useEffect } from "react";
import UndanganContextProvider from "./context/UndanganContextProvider";
import { KONFIGURASI } from "./consts";
import Meta from "./components/Meta";
import Menu from "./components/Menu";
import Welcome from "./components/Welcome";
import Main from "./components/Main/Main";
import Footer from "./components/Footer";
import "./assets/css/style.css";
import Music from "./components/Music";
import {useAppContext} from "../../AppContextProvider";

const UndanganIndex = ({ hideSyukuran= true }) => {
	// CONTEXT
	const dataApp = useAppContext();
	
	// Init URLSearchParams
	const searchParams = new URLSearchParams(document.location.search)
	
	// LOCAL STATE
	const [mempelai, setMempelai] = useState({
		pria: {
			photo: KONFIGURASI.MEMPELAI.PRIA.PHOTO,
			nama_lengkap: KONFIGURASI.MEMPELAI.PRIA.NAMA_LENGKAP,
			nama_panggilan: KONFIGURASI.MEMPELAI.PRIA.NAMA_PANGGILAN,
			ayah: KONFIGURASI.MEMPELAI.PRIA.AYAH,
			ibu: KONFIGURASI.MEMPELAI.PRIA.IBU,
			socmed: {
				ig: KONFIGURASI.MEMPELAI.PRIA.SOCMED.IG,
			}
		},
		wanita: {
			photo: KONFIGURASI.MEMPELAI.WANITA.PHOTO,
			nama_lengkap: KONFIGURASI.MEMPELAI.WANITA.NAMA_LENGKAP,
			nama_panggilan: KONFIGURASI.MEMPELAI.WANITA.NAMA_PANGGILAN,
			ayah: KONFIGURASI.MEMPELAI.WANITA.AYAH,
			ibu: KONFIGURASI.MEMPELAI.WANITA.IBU,
			socmed: {
				ig: KONFIGURASI.MEMPELAI.WANITA.SOCMED.IG,
			}
		},
	});
	const [namaTamu, setNamaTamu] = useState('');
	const [dari, setDari] = useState(null);
	const [syukuran, setSyukuran] = useState({
		tanggal: KONFIGURASI.SYUKURAN.TANGGAL,
		jam: {
			mulai: KONFIGURASI.SYUKURAN.JAM.MULAI,
			selesai: KONFIGURASI.SYUKURAN.JAM.SELESAI,
		},
		tempat: {
			nama: KONFIGURASI.SYUKURAN.TEMPAT.NAMA,
			alamat: KONFIGURASI.SYUKURAN.TEMPAT.ALAMAT,
			latLng: KONFIGURASI.SYUKURAN.TEMPAT.LAT_LNG,
			url: KONFIGURASI.SYUKURAN.TEMPAT.URL,
		}
	});
	const [resepsi, setResepsi] = useState({
		tanggal: KONFIGURASI.RESEPSI.TANGGAL,
		jam: {
			mulai: KONFIGURASI.RESEPSI.JAM.MULAI,
			selesai: KONFIGURASI.RESEPSI.JAM.SELESAI,
		},
		tempat: {
			nama: KONFIGURASI.RESEPSI.TEMPAT.NAMA,
			alamat: KONFIGURASI.RESEPSI.TEMPAT.ALAMAT,
			latLng: KONFIGURASI.RESEPSI.TEMPAT.LAT_LNG,
			url: KONFIGURASI.RESEPSI.TEMPAT.URL,
		},
	});
	const [akad, setAkad] = useState({
		tanggal: KONFIGURASI.AKAD.TANGGAL,
		jam: {
			mulai: KONFIGURASI.AKAD.JAM.MULAI,
			selesai: KONFIGURASI.AKAD.JAM.SELESAI,
		},
		tempat: {
			nama: KONFIGURASI.AKAD.TEMPAT.NAMA,
			alamat: KONFIGURASI.AKAD.TEMPAT.ALAMAT,
			latLng: KONFIGURASI.AKAD.TEMPAT.LAT_LNG,
			url: KONFIGURASI.AKAD.TEMPAT.URL,
		},
	});
	
	const [showContent, setShowContent] = useState(false);

	const [isPlaying, setIsPlaying] = useState(false);

	function ucwords(str) {
		return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
			return $1.toUpperCase();
		});
	}

	// USE EFFECT HOOK
	useEffect(() => {
		if (searchParams.has('buat')) {
			setNamaTamu(searchParams.get('buat'));
		} else if (dataApp?.subsubdomain) {
			const namaTamu = dataApp?.subsubdomain?.replace(/-/g, ' ')
			setNamaTamu(ucwords((namaTamu)));
		}
	}, [namaTamu]);
	
	useEffect(() => {	
		if (searchParams.has('dari')) {
			setDari(searchParams.get('dari'));
		}
	}, [dari]);

	useEffect(() => {
		const handleContextmenu = e => {
			e.preventDefault()
		}
		document.addEventListener('contextmenu', handleContextmenu)
		return function cleanup() {
			document.removeEventListener('contextmenu', handleContextmenu)
		}
	}, [ ])


	// RENDER
	return <div>
		<UndanganContextProvider value={{
			hideSyukuran,
			mempelai,
			namaTamu,
			syukuran,
			akad,
			resepsi,
			setShowContent,
			isPlaying,
			setIsPlaying,
			dari,
		}}>
			<Meta />
			{showContent && <>
				<Music />
				<Menu />
				<Main />
				<Footer />
			</> }
			{/* Welcome */}
			<Welcome />
		</UndanganContextProvider>
	</div>
};

export default UndanganIndex;
