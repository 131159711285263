import {Helmet} from "react-helmet-async";
import {useState} from "react";
import {useUndanganContext} from "../context/UndanganContextProvider";

const Meta = () => {
	// CONTEXT
	const data = useUndanganContext();
	
	// LOCAL STATE
	const [namaPria, setNamaPria] = useState(data?.mempelai?.pria?.nama_panggilan);
	const [namaWanita, setNamaWanita] = useState(data?.mempelai?.wanita?.nama_panggilan);

	return (
		<Helmet>
			{/* Common Tag */}
			<meta charset="utf-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<title>Undangan Pernikahan {namaPria} dan {namaWanita}</title>

			{/* SEO Tag */}
			<meta name="author" content="ferdhika31" />
			<meta name="language" content="Indonesia" />
			<meta name="robots" content="all, max-image-preview:large" />
			<meta name="googlebot" content="all, max-image-preview:large" />
			<meta name="title" content={`Undangan Pernikahan ${namaPria} dan ${namaWanita}`} />
			<meta name="description" content={`Undangan Pernikahan ${namaPria} dan ${namaWanita}`} />
			<meta name="keywords" content="" />
			<meta property="og:title" content={`Undangan Pernikahan ${namaPria} dan ${namaWanita}`} />
			<meta property="og:description" content={`Undangan Pernikahan ${namaPria} dan ${namaWanita}`} />
			<meta property="og:keywords" content="" />
			<meta property="og:image" content="https://ulems.my.id/assets/images/bg.jpeg" />
			<meta property="og:image:secure_url" content="https://ulems.my.id/assets/images/bg.jpeg" />
			<meta property="og:image:type" content="image/jpeg" />
			<meta property="og:image:alt" content={`Undangan Pernikahan ${namaPria} dan ${namaWanita}`} />
			<meta property="og:type" content="website" />
			<meta property="og:locale" content="id_ID" />
			<meta property="og:url" content="https://dika.web.id" />
			<meta property="og:site_name" content={`Undangan Pernikahan ${namaPria} dan ${namaWanita}`} />

			{/* Appearance */}
			<meta name="mobile-web-app-capable" content="yes" />
			<meta name="apple-mobile-web-app-title" content={`Undangan Pernikahan ${namaPria} dan ${namaWanita}`} />
			<meta name="theme-color" content="#111111" />
			<meta name="color-scheme" content="dark" />
			<link rel="canonical" href="https://dika.web.id/" />
			<link rel="apple-touch-icon" sizes="192x192" type="image/png" href="https://ulems.my.id/assets/images/icon-192x192.png" />

			{/*	*/}
			<link rel="dns-prefetch" href="https://cdn.jsdelivr.net" crossOrigin="anonymous" />
			<link rel="dns-prefetch" href="https://fonts.googleapis.com" crossOrigin="anonymous" />
			<link rel="dns-prefetch" href="https://fonts.gstatic.com" crossOrigin />
			<link rel="preconnect" href="https://cdn.jsdelivr.net" crossOrigin="anonymous" />
			<link rel="preconnect" href="https://fonts.googleapis.com" crossOrigin="anonymous" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
			<link rel="preload" href="https://cdn.jsdelivr.net/npm/normalize.css@8.0.1/normalize.css" integrity="sha256-WAgYcAck1C1/zEl5sBl5cfyhxtLgKGdpI3oKyJffVRI=" crossOrigin="anonymous" as="style" />
			<link rel="preload" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" integrity="sha256-MBffSnbbXwHCuZtgPYiwMQbfE7z+GOZ7fBPCNB06Z98=" crossOrigin="anonymous" as="style" />
			<link rel="preload" href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.0/css/all.min.css" integrity="sha256-yIDrPSXHZdOZhAqiBP7CKzIwMQmRCJ8UeB8Jo17YC4o=" crossOrigin="anonymous" as="style" />
			<link rel="preload" href="https://cdn.jsdelivr.net/npm/aos@2.3.4/dist/aos.css" integrity="sha256-GqiEX9BuR1rv5zPU5Vs2qS/NSHl1BJyBcjQYJ6ycwD4=" crossOrigin="anonymous" as="style" />
			<link rel="preload" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha256-gvZPYrsDwbwYJLD5yeBfcNujPhRoGOY831wwbIzz3t0=" crossOrigin="anonymous" as="script" />
			<link rel="preload" href="https://cdn.jsdelivr.net/npm/aos@2.3.4/dist/aos.js" integrity="sha256-pQBbLkFHcP1cy0C8IhoSdxlm0CtcH5yJ2ki9jjgR03c=" crossOrigin="anonymous" as="script" />
			<link rel="preload" href="https://cdn.jsdelivr.net/npm/tsparticles-confetti@2.12.0/tsparticles.confetti.bundle.min.js" integrity="sha256-XG5M9shcLLpu8ct5bVbu6lLVzLpfZChl+csxdyLVP18=" crossOrigin="anonymous" as="script" />

			{/*	*/}
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/normalize.css@8.0.1/normalize.css" integrity="sha256-WAgYcAck1C1/zEl5sBl5cfyhxtLgKGdpI3oKyJffVRI=" crossOrigin="anonymous" />
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" integrity="sha256-MBffSnbbXwHCuZtgPYiwMQbfE7z+GOZ7fBPCNB06Z98=" crossOrigin="anonymous" />
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.0/css/all.min.css" integrity="sha256-yIDrPSXHZdOZhAqiBP7CKzIwMQmRCJ8UeB8Jo17YC4o=" crossOrigin="anonymous" />
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/aos@2.3.4/dist/aos.css" integrity="sha256-GqiEX9BuR1rv5zPU5Vs2qS/NSHl1BJyBcjQYJ6ycwD4=" crossOrigin="anonymous" />

			{/*	*/}
			<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CPoppins%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CAbhaya+Libre%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CDancing+Script%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=swap&#038;ver=6.4.2' type='text/css' media='all' />
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap" />
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Sacramento&display=swap" />
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic&display=swap" />
			
		</Helmet>
	)
}

export default Meta;