import {useCallback, useRef, useState} from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import {useUndanganContext} from "../context/UndanganContextProvider";
import lagu from "../assets/musics/derai-relate_to_you.mp3";
import "./Welcome.css";

const canvasStyles = {
	position: "fixed",
	pointerEvents: "none",
	width: "100%",
	height: "100%",
	top: 0,
	left: 0
};

const Welcome = () => {
	// CONTEXT
	const data = useUndanganContext();
	
	// REF
	const refAnimationInstance = useRef(null);
	
	function getAnimationSettings() {
		const duration = 10 * 1000;
		const animationEnd = Date.now() + duration;
		let skew = 1;
		
		const timeLeft = animationEnd - Date.now();
		const ticks = Math.max(200, 500 * (timeLeft / duration));

		skew = Math.max(0.8, skew - 0.001);
		return {
			particleCount: 1,
			startVelocity: 0,
			ticks: ticks,
			origin: {
				x: Math.random(),
				y: Math.random() * skew - 0.2,
			},
			colors: ["FFC0CB", "FF69B4", "FF1493", "C71585"],
			shapes: ["heart"],
			gravity: randomInRange(0.5, 1),
			scalar: randomInRange(1, 2),
			drift: randomInRange(-0.5, 0.5),
		};
	}

	const [intervalId, setIntervalId] = useState();

	const getInstance = useCallback((instance) => {
		refAnimationInstance.current = instance;
	}, []);

	const nextTickAnimation = useCallback(() => {
		if (refAnimationInstance.current) {
			refAnimationInstance.current(getAnimationSettings());
		}
	}, []);
	
	// LOCAL STATE
	const [opacity, setOpacity] = useState(1);
	const [visibility, setVisibility] = useState("visible");
	const [animation, setAnimation] = useState('');
	const [namaPria, setNamaPria] = useState(data?.mempelai?.pria?.nama_panggilan);
	const [namaWanita, setNamaWanita] = useState(data?.mempelai?.wanita?.nama_panggilan);

	function randomInRange(min, max) {
		return Math.random() * (max - min) + min;
	}
	
	const buka = (e) => {
		data.setShowContent(true);
		setAnimation('bounce-out 1s ease-in');
		setTimeout(() => {
			setOpacity(0);
			setVisibility('hidden');
		}, 1000);
		playAnimation();
	}

	const playAnimation = useCallback(() => {
		// Confetti
		if (refAnimationInstance.current) {
			refAnimationInstance.current({
				origin: { y: 0.8 },
				zIndex: 1057,
			});
		}
		if (!intervalId) {
			nextTickAnimation();
			setIntervalId(setInterval(nextTickAnimation, 400));
		}
	}, []);

	return (<>
		<ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
		<div className="loading-page" id="welcome" style={{ opacity: opacity, visibility: visibility, animation: animation}}>
			<div className="d-flex justify-content-center align-items-center wrapper-welcome">

				<div className="text-center">
					<h3 className="text-light mb-4" style={{fontSize: "1.5rem"}}>The Wedding Of</h3>

					<h1 className="text-light my-4" style={{fontSize: "2.5rem"}}>{ namaWanita } & { namaPria }</h1>

					{data?.namaTamu &&
					<div id="nama-tamu">
						<div className="m-2">
							<p className="mt-0 mb-1 mx-0 p-0 text-light">
								Kepada Yth Bapak/Ibu/Saudara/i
							</p>
							<h3 className="text-light">{data?.namaTamu}</h3>
						</div>
					</div>
					}

					<div className="btn btn-light shadow rounded-4 mt-4" onClick={buka}>
						<i className="fa-solid fa-envelope-open me-2"></i>Buka Undangan
					</div>
				</div>
			</div>
		</div>
	</>)
}

export default Welcome;